<template>
  <!--    Edit-->
  <b-card
      v-if="$route.name == 'Order edit'"
  >
    <!-- Button: Edit -->
    <button-edit
        v-if="order.number == null"
        @click="$emit('submitForm')"
        :block="true"
    />
  </b-card>

  <!--    Unarchive-->
  <b-card
      v-else-if="order.isArchived"
  >
    <!-- Button: Unarchive -->
    <button-unarchivate
        @click="$emit('unarchivateOrder')"
        :block="true"
    />
  </b-card>

  <!--    View-->
  <div v-else>
    <b-card>

      <!-- Button: Validate order -->
      <button-validate
          v-if="order.number == null && $can('manage', 'all')"
          @click="$emit('validateOrder')"
          :block="true"
          class="mb-75"
      />

      <!-- Button: Accepted order -->
      <button-accept
          v-if="order.number != null && !isOfferAccepted"
          :text="'orderAccepted'"
          @click="$emit('acceptOrder')"
          :block="true"
          class="mb-75"
      />

      <!--          Invoice -->
      <button-to-invoice
          v-if="order.number != null && isOfferAccepted"
          @click="$emit('billOrder')"
          :block="true"
          class="mb-75"
      />

      <!--          Archivate -->
      <button-archivate
          v-if="order.number != null && isOfferAccepted && !order.isArchived"
          @click="$emit('archivateOrder')"
          :block="true"
          class="mb-75"
      />

      <!-- Button: Edit -->
      <button-edit
          v-if="order.number == null"
          @click="$router.push({ name: 'Order edit', params: { orderId: $route.params.orderId } })"
          :block="true"
          class="mb-75"
      />

      <!--          Duplicate -->
      <button-duplicate
          @click="$emit('duplicateOrder')"
          :block="true"
      />


      <!--          Delete -->
      <button-delete
          v-if="order.number == null"
          @click="$emit('removeOrder')"
          :block="true"
      />

    </b-card>
  </div>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useOrders } from '../useOrders'
import { capitalize } from '@/utils/filter'

import store from '@/store'
import Ripple from 'vue-ripple-directive'
import CardDocument from '@/components/card/Document'
import ButtonValidate from '@/components/button/Validate'
import ButtonEdit from '@/components/button/Edit'
import ButtonDuplicate from '@/components/button/Duplicate'
import ButtonDelete from '@/components/button/Delete'
import ButtonAccept from '@/components/button/Accept'
import ButtonToInvoice from '@/components/button/ToInvoice'
import ButtonArchivate from '@/components/button/Archivate'
import ButtonUnarchivate from '@/components/button/Unarchivate'

export default {
  directives: {
    Ripple,
  },
  components: {
    ButtonValidate,
    ButtonEdit,
    ButtonDuplicate,
    ButtonDelete,
    ButtonAccept,
    ButtonToInvoice,
    ButtonArchivate,
    ButtonUnarchivate,
    CardDocument,

  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isOfferAccepted = computed(() => {
      return (
          props.order.signedDocument != null ||
          props.order.signedDocumentDate != null ||
          props.order.signedDocumentNumber != null
      )
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const download = (documentToDownload) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = documentToDownload.url
      link.click()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      isOfferAccepted,

      // Methods
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () { },
  created () { }
}
</script>

<style lang="scss">

</style>