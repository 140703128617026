import { ref, computed, watch } from '@vue/composition-api'
import { useOrders } from './useOrders'
import { useSupplierInvoices } from '../supplierInvoice/useSupplierInvoices'
import { capitalize, currency } from '@/utils/filter'
import { getRoute } from '@/utils/utils'

import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'

import ModalOrder from '@/components/prompt/Order'
import ModalOrderValidation from '@/components/prompt/OfferValidation'
import incomingInvoice from '../../../store/incomingInvoice/incomingInvoice'
import useAPI from '../../../utils/useAPI'

export const mixinOrders = {
  components: {
    ModalOrder,
    ModalOrderValidation
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const orderModalShow = ref(false)
    const orderModalTitle = ref(i18n.t('NewOrder'))
    const currentOrder = ref({})
    const isOrderAcceptanceModalOpen = ref(false)
    const submitOrderFormRef = ref(null)

    const incomingInvoiceByUploadModalShow = ref(false)
    const newIncomingInvoiceFile = ref(null)
    const newIncomingInvoice = ref(null)
    const incomingInvoiceModalShow = ref(null)
    const incomingInvoiceModalTitle = ref(i18n.t('NewIncomingInvoice'))

    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(orderModalShow, () => {
      if (orderModalShow.value == false) {
        resetOrder()
      }
    })

    watch(currentOrder, () => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        currentOrder.value.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }

        if (
          !('id' in currentOrder.value) &&
          currentOrder.value.supplierCompany == null
        ) {
          currentOrder.value.supplierCompany = workflow.customerCompany
        }
      }

    })

    watch(newIncomingInvoiceFile, (val) => {
      if (val != null) {
        recognizeIncomingInvoice(val)
          .then(response => {
            newIncomingInvoice.value = response.data

            populateInvoiceWithOrder(
              JSON.parse(JSON.stringify(currentOrder.value)),
              newIncomingInvoice.value
            )

            incomingInvoiceModalShow.value = true
          })
      }
      incomingInvoiceByUploadModalShow.value = false
    })

    watch(incomingInvoiceModalShow, val => {
      if (val == false) {
        newIncomingInvoiceFile.value = null
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchArticles,
      fetchCompanies,
      fetchOrders,
      fetchPaymentMethods,
      fetchPaymentTerms,
      fetchVats,
      fetchUsers,
      fetchWorkflows,
      fetchAutocompleteArticles
    } = useAPI()

    const {
      submitValidatedOrder,
      removeOrder,
      validateOrder,
      downloadOrder,
      downloadOrderIncomingInvoice,
      recognizeIncomingInvoice
    } = useOrders()

    const { submitValidatedIncomingInvoice, removeIncomingInvoice } = useSupplierInvoices()
    // const { submitValidatedIncomingInvoice, removeIncomingInvoice } = useIncomingInvoices()

    const addOrder = () => {
      orderModalShow.value = true
    }

    const selectOrder = (order) => {
      orderModalTitle.value = i18n.t('EditOrder')

      fetchOrders(order.id).then((order) => {
        currentOrder.value = order

        orderModalShow.value = true
      })

      // currentOrder.value = JSON.parse(JSON.stringify(order))
      // orderModalShow.value = true
    }

    const tryCloseOrderModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitOrderFormRef.value.click()
      }
    }

    const submitValidatedOrderLocal = () => {
      submitValidatedOrder(currentOrder.value)
        .then(response => {
          orderModalShow.value = false
          isOrderAcceptanceModalOpen.value = false
          incomingInvoiceByUploadModalShow.value = false
          incomingInvoiceModalShow.value = false

          if (getRoute().name == 'Workflow view') {
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            // delete response.data.workflow
            response.data.workflow = response.data.workflow.id

            if ('id' in currentOrder.value) {
              let index = workflow.orders.findIndex(i => i.id == currentOrder.value.id)
              workflow.orders.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
            } else {
              workflow.orders.push(response.data)
            }

          }
        })
    }

    const resetOrder = () => {
      currentOrder.value = JSON.parse(JSON.stringify(store.getters['order/getEmptyOrder']))
    }

    const validateOrderLocal = (order) => {
      fetchOrders(order.id).then((order) => {
        validateOrder(order)
          .then(response => {
            if (getRoute().name == 'Workflow view') {
              let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
              // delete response.data.workflow
              response.data.workflow = response.data.workflow.id
              let index = workflow.orders.findIndex(i => i.id == order.id)
              workflow.orders.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
            }
          })
      })
    }

    const acceptOrderLocal = (order) => {
      // console.log(order)
      fetchOrders(order.id).then((order) => {
        currentOrder.value = order
        currentOrder.value.signedDocumentDate = (new Date()).toISOString().slice(0, 10)
        isOrderAcceptanceModalOpen.value = true
      })

    }

    const duplicateOrder = (order) => {
      fetchOrders(order.id).then((order) => {
        currentOrder.value = order
        delete currentOrder.value.number
        delete currentOrder.value.incomingInvoice
        delete currentOrder.value.id
        delete currentOrder.value.signedDocument
        delete currentOrder.value.signedDocumentDate
        delete currentOrder.value.signedDocumentNumber

        orderModalShow.value = true
      })
    }

    const removeOrderLocal = (order) => {
      removeOrder(order)
        .then(response => {
          if (getRoute().name == 'Order view') {
            router.push({ name: 'Orders' })
          } else if (getRoute().name == 'Workflow view') {
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            workflow.orders = workflow.orders.filter(ii => ii.id != order.id)
          }
        })
    }

    const billOrder = (order) => {
      // fetchOrders(order.id).then((order) => {
        currentOrder.value = order
        incomingInvoiceByUploadModalShow.value = true
      // })
    }

    const archivateOrder = (order) => {
      currentOrder.value = JSON.parse(JSON.stringify(order))
      currentOrder.value.isArchived = true

      submitValidatedOrderLocal()
    }

    const unarchivateOrder = (order) => {
      currentOrder.value = JSON.parse(JSON.stringify(order))
      currentOrder.value.isArchived = false

      submitValidatedOrderLocal()
    }

    const submitValidatedIncomingInvoiceLocal = () => {
      delete newIncomingInvoice.value.incomingInvoice

      submitValidatedIncomingInvoice(newIncomingInvoice.value)
        .then(response => {
          console.log(currentOrder.value)
          let order = store.getters['order/getOrder'](currentOrder.value.id)
          if (getRoute().name == 'Workflow view') {
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            order = workflow.orders.find(o => o.id == currentOrder.value.id)
          }

          if ('id' in newIncomingInvoice.value && newIncomingInvoice.value.id != null) {
            // EDIT
            let index = order._linkedIncomingInvoices.findIndex(i => i.id == newIncomingInvoice.value.id)
            order._linkedIncomingInvoices[index] = JSON.parse(JSON.stringify(newIncomingInvoice.value))

            componentKey.value++
          } else {
            order._linkedIncomingInvoices.push(response.data)
            order.products.forEach(op => {
              let ip = response.data.products.find(p => p.parent.id == op.id)
              if (ip !== undefined) {
                op._remaining = parseFloat(op._remaining) - ip.quantity
              }
            })
          }

          order._filters[3].value = true

          incomingInvoiceModalShow.value = false
        })
    }

    const editIncomingInvoice = (order, incomingInvoice) => {
      currentOrder.value = JSON.parse(JSON.stringify(order))
      newIncomingInvoice.value = JSON.parse(JSON.stringify(incomingInvoice))

      incomingInvoiceModalShow.value = true
    }

    const removeIncomingInvoiceLocal = (order, incomingInvoiceIndex) => {
      let incomingInvoice = JSON.parse(JSON.stringify(order._linkedIncomingInvoices[incomingInvoiceIndex]))
      // console.log(incomingInvoice)
      removeIncomingInvoice(incomingInvoice)
        .then(response => {
          incomingInvoice.products.forEach(ip => {
            let op = order.products.find(op => op.id == ip.parent.id)
            // console.log(op)
            if (op !== undefined) {
              op._remaining += ip.quantity
            }
          })

          order._linkedIncomingInvoices.splice(incomingInvoiceIndex, 1)
        })
    }

    const getPreTaxAmounts = (orders) => {
      let preTaxAmount = 0
      orders.forEach(o => preTaxAmount += parseFloat(o.preTaxAmount))

      return preTaxAmount
    }

    const getTotalAmounts = (orders) => {
      let totalAmount = 0
      orders.forEach(o => totalAmount += parseFloat(o.totalAmount))

      return totalAmount
    }

    const populateInvoiceWithOrder = (order, invoice) => {
      if (invoice.discount == null && order.discount != null) {
        invoice.discount = order.discount
        invoice.isDiscountPercent = order.isDiscountPercent
      }

      if (invoice.paymentDate == null) {
        invoice.paymentDate = order._dueDate
      }

      if (invoice.paymentMethod == null) {
        invoice.paymentMethod = order.paymentMethod
      }

      if (invoice.pretaxAmount == null) {
        invoice.pretaxAmount = order.pretaxAmount
      }

      if (invoice.vat == null) {
        invoice.vat = order.vat
      }

      if (invoice.totalAmount == null) {
        invoice.totalAmount = order.totalAmount
      }

      invoice.products = []
      order.products.forEach(op => {
        if (('_selectedQuantity' in op) && op._selectedQuantity != 0) {
          let ip = JSON.parse(JSON.stringify(op))
          delete ip.id
          ip.parent = op.id
          ip.quantity = ip._selectedQuantity
          invoice.products.push(ip)
        }
      })
      if (invoice.products.length == 0) {
        order.products.forEach(op => {
          let ip = JSON.parse(JSON.stringify(op))
          delete ip.id
          ip.parent = op.id
          ip.quantity = op.quantity
          invoice.products.push(ip)
        })
      }

      if (invoice.supplierCompany == null) {
        invoice.supplierCompany = order.supplierCompany
      }

      if (order.workflow != null) {
        invoice.workflow = order.workflow
      }

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name.startsWith('Order')) {
      if (getRoute().params != null && ('orderId' in getRoute().params)) {
        fetchOrders(getRoute().params.orderId).then(() => {
          fetchAutocompleteArticles()
          fetchCompanies()
          fetchPaymentMethods()
          fetchPaymentTerms()
          fetchVats()
          fetchUsers()
          fetchWorkflows()
        })
      } else {
        fetchOrders().then(() => {
          fetchAutocompleteArticles()
          fetchCompanies()
          fetchPaymentMethods()
          fetchPaymentTerms()
          fetchVats()
          fetchUsers()
          fetchWorkflows()
        })
      }
    }

    resetOrder()

    return {
      // Components
      capitalize,
      currency,

      // Data
      orderModalShow,
      orderModalTitle,
      currentOrder,
      isOrderAcceptanceModalOpen,
      submitOrderFormRef,
      incomingInvoiceByUploadModalShow,
      newIncomingInvoiceFile,
      newIncomingInvoice,
      incomingInvoiceModalShow,
      incomingInvoiceModalTitle,
      componentKey,

      // Computed

      // Methods
      addOrder,
      selectOrder,
      tryCloseOrderModal,
      submitValidatedOrderLocal,
      downloadOrder,
      downloadOrderIncomingInvoice,
      recognizeIncomingInvoice,
      removeOrder,
      validateOrderLocal,
      acceptOrderLocal,
      duplicateOrder,
      removeOrderLocal,
      billOrder,
      archivateOrder,
      unarchivateOrder,
      submitValidatedIncomingInvoiceLocal,
      editIncomingInvoice,
      removeIncomingInvoiceLocal,
      getPreTaxAmounts,
      getTotalAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    // newIncomingInvoiceFile (val) {
    //   this.recognizeIncomingInvoice(val)
    //     .then(result => {
    //       console.log(result.data)
    //       console.log(JSON.parse(JSON.stringify(this.currentOrder)))
    //       console.log(this.currentOrder)
    //       this.currentOrder.incomingInvoice = result.data
    //       this.currentOrder.incomingInvoice.orders = [this.currentOrder.id]
    //       this.incomingInvoiceByUploadModalShow = false
    //
    //       this.$nextTick(() => {
    //         this.incomingInvoiceModalShow = true
    //       })
    //
    //     })
    // }
  },
  methods: {
    deleteOrderAlert (order) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theOrder') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeOrderLocal(order)
          }
        })
    },
    deleteIncomingInvoiceAlert (order, incomingInvoiceIndex) {
      // console.log(order, incomingInvoiceIndex)
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theIncomingInvoice') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeIncomingInvoiceLocal(order, incomingInvoiceIndex)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}